<template>
	<div>
		<h3>Manage Clients</h3>
		<form id="new-client" @submit.prevent="saveNewClient">
			<h4>New Client</h4>
			<input v-model.trim="newClient.name" placeholder="Name" type="text" />
			<input v-model.trim="newClient.email" placeholder="Email" type="text" />
			<p><small>Separate multiple addresses by a comma.</small></p>
			<button
				type="submit"
				:disabled="isSavingNewClient"
				:class="{ 'is-loading': isSavingNewClient }"
			>
				Add Client
				<svg-icon v-if="isSavingNewClient" icon="spinner" class="spinner" pulse />
			</button>
		</form>
		<ast-card v-for="consignerClient in consignerClients" :key="consignerClient.id" class="card">
			<form
				v-if="unsavedClient.id === consignerClient.id"
				:id="'edit-' + consignerClient.id"
				key="form"
				@submit.prevent="saveEditingClient"
			>
				<input v-model.trim="unsavedClient.name" type="text" placeholder="Name" />
				<input v-model.trim="unsavedClient.email" type="text" placeholder="Email" />
				<p><small>Separate multiple addresses by a comma.</small></p>
				<button type="submit" :disabled="isSavingClient" :class="{ 'is-loading': isSavingClient }">
					<svg-icon icon="floppy-o" />
					Save
					<svg-icon v-if="isSavingClient" icon="spinner" class="spinner" pulse />
				</button>
				<button :disabled="isSavingClient" type="button" @click="cancelEditingClient()">
					Cancel
				</button>
			</form>
			<div v-else key="values">
				<p>
					<span class="client-name">{{ consignerClient.name }}</span>
					{{ consignerClient.email }}
				</p>
				<button
					v-if="unsavedClient.id !== consignerClient.id"
					type="button"
					@click="editClient(consignerClient)"
				>
					<svg-icon icon="edit" />
					Edit
				</button>
				<button type="button" :disabled="isEmailingClient" @click="emailClient(consignerClient)">
					<svg-icon icon="email" />
					Send Link
				</button>
			</div>
		</ast-card>
	</div>
</template>

<script>
import AstCard from '@components/Card'
import clone from '@utils/deepClone'

export default {
	components: {
		AstCard,
	},
	props: {
		consignerClients: {
			type: Array,
			required: true,
		},
		consignerId: {
			type: String,
			required: true,
		},
		clinicCode: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			unsavedClient: {},
			newClient: {},
			isSavingClient: false,
			isEmailingClient: false,
			isSavingNewClient: false,
		}
	},
	methods: {
		editClient(consignerClient) {
			if (this.unsavedClient.id) this.confirmSaveEdit()
			this.unsavedClient = clone(consignerClient)
		},
		confirmSaveEdit() {
			if (!this.hasUnsavedEdits()) return
			if (window.confirm('Save changes to the client you are currently editing?')) {
				this.saveEditingClient()
			}
		},
		async saveEditingClient() {
			let clientToUpdate = this.consignerClients.find(client => {
				return client.id === this.unsavedClient.id
			})
			Object.assign(clientToUpdate, this.unsavedClient)
			const updateClientData = {
				consignerId: this.consignerId,
				clinicCode: this.clinicCode,
				id: this.unsavedClient.id,
				name: this.unsavedClient.name,
				email: this.unsavedClient.email.split(',').map(email => email.trim()),
			}
			this.isSavingClient = true
			try {
				await this.$api.statistics.updateConsignerClient(updateClientData)
				this.unsavedClient = {}
			} finally {
				this.isSavingClient = false
			}
		},
		hasUnsavedEdits() {
			if (
				this.consignerClients.find(client => {
					return (
						client.id === this.unsavedClient.id &&
						client.name === this.unsavedClient.name &&
						client.email === this.unsavedClient.email
					)
				})
			)
				return false
			return true
		},
		cancelEditingClient() {
			this.unsavedClient = {}
		},
		async emailClient(consignerClient) {
			const emailClientData = {
				consignerClientId: consignerClient.id,
				clinicCode: this.clinicCode,
			}
			this.isEmailingClient = true
			try {
				await this.$api.statistics.emailConsignerClient(emailClientData)
				this.$store.dispatch('addNotification', {
					message: 'Link sent!',
					notificationType: 'success',
				})
			} finally {
				this.isEmailingClient = false
			}
		},
		async saveNewClient() {
			if (!this.newClient.name || !this.newClient.email) return
			this.isSavingNewClient = true
			try {
				const newClientData = {
					consignerId: this.consignerId,
					clinicCode: this.clinicCode,
					name: this.newClient.name,
					email: this.newClient.email.split(',').map(email => email.trim()),
				}
				const { data } = await this.$api.statistics.createConsignerClient(newClientData)
				this.consignerClients.push(Object.assign({}, this.newClient, { id: data }))
				this.newClient = {}
			} finally {
				this.isSavingNewClient = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
form#new-client {
	padding: 0.5em;
	margin-bottom: 1em;
}
input {
	width: 90%;
	margin: 0.5em 0;
}
button {
	margin: 0.5em 0.5em 0.5em 0;
}
.client-name {
	padding-right: 0.5em;
	font-weight: 700;
}
</style>
