<template>
	<statistics-layout :is-loading="isLoading">
		<statistics-sidebar v-if="showClientManager && !isLoading" @close="toggleClientManager">
			<statistics-consigner-clients-manager
				:consigner-clients="consignerClients"
				:consigner-id="id"
				:clinic-code="clinicCode"
				@update="newConsignerClients => (consignerClients = newConsignerClients)"
			/>
		</statistics-sidebar>

		<button type="button" @click="toggleClientManager">Manage Clients</button>
		<div v-if="saleEntries.length && !isLoading">
			<data-table
				v-if="mq.xLarge"
				:is-compact="true"
				:is-hoverable="false"
				:is-full-width="false"
				class="sale-entry-table"
			>
				<thead>
					<tr>
						<th>{{ translate('consignerStudy') }}</th>
						<th>Category</th>
						<th>Sire</th>
						<th>Dam</th>
						<th>Chip Id</th>
						<th>Clients</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="saleEntry in saleEntries" :key="saleEntry.saleEntryId">
						<td>{{ saleEntry.lotNumber }}</td>
						<td>{{ saleEntry.category }}</td>
						<td>{{ saleEntry.sire }}</td>
						<td>{{ saleEntry.dam }}</td>
						<td>{{ saleEntry.chipId }}</td>
						<td>
							<v-select
								v-model="saleEntry.clientIds"
								:options="consignerClientOptions"
								:get-option-label="getConsignerClientName"
								:searchable="true"
								multiple
								@input="clientIds => updateSaleEntryClients(saleEntry, clientIds)"
							/>
						</td>
					</tr>
				</tbody>
			</data-table>
			<div v-if="!mq.xLarge">
				<ast-card v-for="saleEntry in saleEntries" :key="saleEntry.saleEntryId" class="card">
					<div class="row">
						<div class="col-sm-12 col-md-6">
							<h3>{{ translate('consignerStudy') }} #{{ saleEntry.lotNumber }}</h3>
							<ul>
								<li>
									<label>Category:</label>
									{{ saleEntry.category }}
								</li>
								<li>
									<label>Sire:</label>
									{{ saleEntry.sire }}
								</li>
								<li>
									<label>Dam:</label>
									{{ saleEntry.dam }}
								</li>
								<li>
									<label>Chip Id:</label>
									{{ saleEntry.chipId }}
								</li>
							</ul>
						</div>
						<div class="col-sm-12 col-md-6">
							<h3>Clients:</h3>
							<v-select
								v-model="saleEntry.clientIds"
								:options="consignerClientOptions"
								:get-option-label="getConsignerClientName"
								:searchable="true"
								multiple
								@input="clientIds => updateSaleEntryClients(saleEntry, clientIds)"
							/>
						</div>
					</div>
				</ast-card>
			</div>
		</div>
		<p v-if="!saleEntries.length && !isLoading" class="placeholder">
			No sale entries found.
		</p>
	</statistics-layout>
</template>

<script>
import StatisticsLayout from '@router/layouts/Statistics'
import StatisticsSidebar from '@components/layout/StatisticsSidebar'
import AstCard from '@components/Card'
import DataTable from '@components/DataTable'
import StatisticsConsignerClientsManager from '@components/view/StatisticsConsignerClientsManager'
export default {
	components: {
		StatisticsLayout,
		StatisticsSidebar,
		AstCard,
		DataTable,
		StatisticsConsignerClientsManager,
	},
	props: {
		clinicCode: {
			type: String,
			required: true,
		},
		id: {
			type: String,
			required: true,
		},
		showClientManager: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isLoading: true,
			saleEntries: [],
			consignerClients: [],
		}
	},
	computed: {
		consignerClientOptions() {
			return this.consignerClients.map(x => x.id)
		},
	},
	watch: {
		'$route.query.saleId'() {
			this.loadData()
		},
	},
	mounted() {
		this.loadData()
	},
	methods: {
		async loadData() {
			this.isLoading = true
			try {
				const [consignerClients, saleEntries] = await Promise.all([
					this.$api.statistics.getConsignerClients({
						clinicCode: this.clinicCode,
						consignerId: this.id,
					}),
					this.$api.statistics.getSaleEntryClients({
						clinicCode: this.clinicCode,
						saleId: this.$route.query.saleId,
						consignerId: this.id,
					}),
				])
				this.consignerClients = consignerClients.data
				this.saleEntries = saleEntries.data.map(saleEntry => {
					// create array of clientIds for vue-select to use as model
					return Object.assign(saleEntry, {
						clientIds: saleEntry.clients.map(client => client.clientId),
					})
				})
			} finally {
				this.isLoading = false
			}
		},
		getConsignerClientName(id) {
			const consignerClient = this.consignerClients.find(x => x.id === id)
			if (consignerClient) return consignerClient.name
		},
		updateSaleEntryClients(saleEntry, clientIds) {
			const assignedClientIds = clientIds.filter(clientId => {
				return !saleEntry.clients.map(x => x.clientId).includes(clientId)
			})
			const unassignedAssociationIds = saleEntry.clients
				.filter(client => {
					return !clientIds.includes(client.clientId)
				})
				.map(client => client.id)
			this.assignSaleEntryClients(saleEntry, assignedClientIds)
			this.unassignSaleEntryClients(saleEntry, unassignedAssociationIds)
		},
		assignSaleEntryClients(saleEntry, clientIds) {
			try {
				clientIds.forEach(async clientId => {
					const { data } = await this.$api.statistics.assignClientToSaleEntry({
						clientId,
						saleEntryId: saleEntry.id,
						clinicCode: this.clinicCode,
					})
					saleEntry.clients.push({
						id: data,
						clientId,
					})
				})
			} finally {
			}
		},
		unassignSaleEntryClients(saleEntry, associationIds) {
			try {
				associationIds.forEach(associationId => {
					this.$api.statistics.unassignClientToSaleEntry({
						associationId,
						saleEntryId: saleEntry.id,
						clinicCode: this.clinicCode,
					})
					saleEntry.clients.splice(saleEntry.clients.findIndex(client => client.id === associationId), 1)
				})
			} finally {
			}
		},
		toggleClientManager() {
			if (this.showClientManager) {
				this.$router.push({ name: 'statistics-consigner-clients', query: this.$route.query })
			} else {
				this.$router.push({ name: 'statistics-consigner-clients-manage', query: this.$route.query })
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.sale-entry-table.table-container {
	/* fix dropdown cut-off issue */
	overflow: visible;
}
.data-table thead > tr > th {
	position: initial; // turn off sticky headers due to v-select weirdness
}
</style>
